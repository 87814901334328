<template>
    <section
        v-show="calendarView !== 'timeline'"
        v-loading="$wait.is('loading.assigned_hours')"
        class="mb-4 assigned-hours"
        :class="{ dimmed: calendarView != 'month' }"
        element-loading-spinner="el-custom-spinner"
    >
        <h3>{{ $t('calendar.assigned_hours') }}</h3>
        <div class="flex justify-between font-semibold mt-2">
            <p class="text-xs">
                {{ $t('calendar.total_hours') }}
            </p>
            <p class="text-xs">
                {{ normalizeTime(totalHours) }}
            </p>
        </div>
        <div class="flex justify-between font-semibold mt-2">
            <p class="text-xs">
                {{ $t('calendar.without_assignment') }}
            </p>
            <p class="text-xs">
                {{ normalizeTime(withoutAssignment) }}
            </p>
        </div>
        <div v-for="(employee, key) in employeesHours" :key="key" class="employee-container">
            <p class="text-xs">
                {{ employee.name }} {{ employee.surname }}
            </p>
            <p class="text-xs">
                {{ normalizeTime(employee.time) }}
            </p>
        </div>
    </section>
</template>
<script>
export default {
    props: {
        calendarView: {
            type:    String,
            default: 'month',
        },
    },

    data() {
        return {
            totalHours:        0,
            employeesHours:    [],
            withoutAssignment: 0,
        };
    },

    computed: {
        selectedDate() {
            return this.$store.state.calendar.selectedDate;
        },
    },

    watch: {
        selectedDate() {
            this.getTotalHours();
        },
        calendarView() {
            this.getTotalHours();
        },
    },

    created() {
        this.getTotalHours();
    },

    methods: {

        async getTotalHours() {
            if (!this.selectedDate) return;
            this.$wait.start('loading.assigned_hours');

            try {
                const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/services/calendar/assigned_hours`, {
                    params: {
                        date: this.selectedDate,
                    },
                });
                this.employeesHours = data.assigned_time;
                this.totalHours = data.assigned_total;
                this.withoutAssignment = data.without_assignment;
                this.$wait.end('loading.assigned_hours');
            } catch (error) {
                this.$wait.end('loading.assigned_hours');
            }
        },
    },
};
</script>
<style scoped>
.checkbox-ctn {
    display: flex;
    height: 33px;
    align-items: center;
    justify-content: center;
}

.btn-not-assigned-employees {
    width: 100%;
}

.employee-container {
    display: flex;
    justify-content: space-between;
}
.employee-container p {
    margin: 5px 0;
    font-weight: 300;
    color: #666;
}

.total-time p {
    font-weight: 500;
}

.dimmed p{
    color: #ececec;
}
</style>
